import { FC, useState, useEffect } from 'react'

import { observer } from 'mobx-react'
import { Table } from 'react-bootstrap'
import { useTranslation } from 'react-i18next'

import { useOnlineStatus } from './OnlineStatusContext'
import { useAppRoot } from './RootContext'
import { Root } from '../../models3/Root'
import { ProjectAddModal } from '../modals/project/AddModal'
import { ProjectDeleteModal } from '../modals/project/DeleteModal'
import MembersByRoleView from '../projectSettings/members/MembersByRoleView'
import { AddButton, DeleteButton } from '../utils/Buttons'
import { GenericIcon, LoadingIcon } from '../utils/Icons'

interface ProjectsEditorProps {
    groupRoot?: Root
}

const ProjectsEditor: FC<ProjectsEditorProps> = observer(({ groupRoot }) => {
    const { t } = useTranslation()
    const [openProjectAddModal, setOpenProjectAddModal] = useState(false)
    const [openProjectDeleteModal, setOpenProjectDeleteModal] = useState<Root>()
    const [loading, setLoading] = useState(false)
    const [projectSearchQuery, setProjectSearchQuery] = useState<string>('')
    const appRoot = useAppRoot()
    const { isOnline } = useOnlineStatus()

    const showDetails = Boolean(groupRoot) // TODO: this can be a user selection

    useEffect(() => {
        let mounted = true

        async function initializeRoots() {
            setLoading(true)
            await Promise.all(
                appRoot.rts
                    .filter((rt) => !groupRoot || groupRoot.groupProjects?.includes(rt.project.name))
                    .map((rt) => rt.initialize())
            )
            if (mounted) setLoading(false)
        }

        if (showDetails) {
            initializeRoots()
        }

        return () => {
            mounted = false
        }
    }, [appRoot.rts, groupRoot, showDetails])

    if (loading) {
        return <LoadingIcon className="" />
    }

    const isGroupAdmin = groupRoot?.iAmAdmin ?? false

    const reloadPage = () => {
        // This only works sometimes when not in a timeout. My guess is there is some
        // kind of race condition.
        setTimeout(() => {
            window.location.reload()
        }, 0)
    }

    const filteredProjects = appRoot.rts
        .filter(
            (rt) =>
                (!groupRoot || groupRoot.groupProjects?.includes(rt.project.name)) &&
                rt.project.getFormattedDisplayName().toLowerCase().includes(projectSearchQuery.toLowerCase())
        )
        .sort((a, b) => {
            const aName = a.project.getFormattedDisplayName().toLowerCase()
            const bName = b.project.getFormattedDisplayName().toLowerCase()
            return aName.localeCompare(bName)
        })

    return (
        <div>
            {openProjectDeleteModal && (
                <ProjectDeleteModal closeModal={reloadPage} projectRoot={openProjectDeleteModal} />
            )}
            {openProjectAddModal && <ProjectAddModal closeModal={reloadPage} groupRoot={groupRoot} />}
            <div className="container-fluid">
                <div className="row">
                    <h3>{groupRoot ? t('Group Projects') : t('All Projects')}</h3>
                </div>
                <div className="row">
                    <div className="col-md-5">
                        {!groupRoot && (
                            <div className="bibles-table-search-input__container">
                                <GenericIcon
                                    className="bibles-table-search-input__icon"
                                    iconName="fa-search"
                                    tooltip={t('searchPublishedBibles')}
                                />
                                <input
                                    onChange={(e) => setProjectSearchQuery(e.target.value)}
                                    type="text"
                                    value={projectSearchQuery}
                                />
                            </div>
                        )}
                        <Table striped className={!groupRoot ? 'projects-table' : ''}>
                            <thead>
                                <tr>
                                    {!groupRoot && <th>{t('Group')}</th>}
                                    <th>{groupRoot ? t('Group Project') : t('Project')}</th>
                                    {showDetails && (
                                        <>
                                            <th>{t('Admin Users')}</th>
                                            <th>
                                                <i className="fas fa-fw fa-video" title={t('project has recordings')} />
                                            </th>
                                        </>
                                    )}
                                    <th />
                                </tr>
                            </thead>
                            <tbody>
                                {filteredProjects.map((rt) => (
                                    <tr key={rt.project.name}>
                                        {!groupRoot && <td>{rt.groupProjects ? rt.name : rt.group}</td>}
                                        <td>{rt.project.getFormattedDisplayName()}</td>
                                        {showDetails && (
                                            <>
                                                <td>
                                                    <MembersByRoleView project={rt.project} includeRoles={['admin']} />
                                                </td>
                                                <td>
                                                    {rt.project.videod() && (
                                                        <i
                                                            className="fas fa-fw fa-video"
                                                            title={t('project has recordings')}
                                                        />
                                                    )}
                                                </td>
                                            </>
                                        )}
                                        {!appRoot.useMobileLayout && (appRoot.iAmRoot || isGroupAdmin) && (
                                            <td>
                                                <DeleteButton
                                                    onClick={() => setOpenProjectDeleteModal(rt)}
                                                    buttonClassName="projects-table-delete-button"
                                                    className="sl-delete-button"
                                                    tooltip={t('Delete Project')}
                                                    enabled={isOnline}
                                                />
                                            </td>
                                        )}
                                    </tr>
                                ))}
                                {!appRoot.useMobileLayout && (appRoot.iAmRoot || isGroupAdmin) && (
                                    <tr>
                                        <td colSpan={groupRoot ? 4 : 3}>
                                            <AddButton
                                                onClick={() => setOpenProjectAddModal(true)}
                                                className="project-plan-copy-button"
                                                buttonClassName=""
                                                tooltip={t('Add project')}
                                                enabled={isOnline}
                                            />
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    )
})

export default ProjectsEditor
